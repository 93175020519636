import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../theme";

import { Box, Button, Container, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import { GetNameChoose } from "../../funcUtils";

function SetTX() {
    const [list30, setList30] = useState();
    const [bet, setBet] = useState(null);
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    const [second, setSecond] = useState(0);
    const [minute, setMinute] = useState(1);
    const [start, setStart] = useState(false);
    const [dulieunhap, setDulieunhap] = useState(new Date());
    const [update, setUpdate] = useState(0);
    const [current, setCurrent] = useState(null);
    const [total, setTotal] = useState(null);

    const date = new Date();
    const currentMinute = date.getMinutes();
    const currentSecond = date.getSeconds();
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/TX45s/getadmin`).then((res) => {
            setBet(res.data.data[0]);
            setDulieunhap(new Date(res.data.data[0].createdAt));
            setStart(true);
        });
        axios
            .get(`${process.env.REACT_APP_API_URL}/TX45s/getallbet`, {})
            .then((res) => {
                setTotal(res.data.data);
            })
            .catch(() => setTotal(null));
        axios
            .get(`${process.env.REACT_APP_API_URL}/TX45s/list30bet`, {})
            .then((res) => {
                setList30(res.data.data);
            })
            .catch(() => setList30(null));
        axios.get(`${process.env.REACT_APP_API_URL}/TX45s/getcurrent`).then((res) => {
            setCurrent(res.data.data);
        });
    }, []);
    useEffect(() => {
        const timer = setInterval(() => {
            if (Math.floor(45 - (new Date() - dulieunhap) / 1000) < 0) {
                axios.get(`${process.env.REACT_APP_API_URL}/TX45s/getadmin`).then((res) => {
                    setBet(res.data.data[0]);
                    setDulieunhap(new Date(res.data.data[0].createdAt));
                });
                axios
                    .get(`${process.env.REACT_APP_API_URL}/TX45s/getallbet`, {})
                    .then((res) => {
                        setTotal(res.data.data);
                    })
                    .catch(() => setTotal(null));
                axios
                    .get(`${process.env.REACT_APP_API_URL}/TX45s/list30bet`, {})
                    .then((res) => {
                        setList30(res.data.data);
                    })
                    .catch(() => setList30(null));

                axios.get(`${process.env.REACT_APP_API_URL}/TX45s/getcurrent`).then((res) => {
                    setCurrent(res.data.data);
                });
            }
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, [dulieunhap]);
    useEffect(() => {
        let curTime_second = Math.floor(45 - (date - dulieunhap) / 1000);
        let myTimeout;

        if (currentMinute === dulieunhap.getMinutes() && currentSecond === dulieunhap.getSeconds()) {
            setStart(true);
            setSecond(second - 1);
            return () => {
                clearTimeout(myTimeout);
            };
        } else if (curTime_second < 45 && curTime_second >= 0) {
            setSecond(curTime_second % 60);
            setMinute((curTime_second - (curTime_second % 60)) / 60);
            setStart(true);
            return () => {
                clearTimeout(myTimeout);
            };
        } else {
            //cập nhật thời gian hiện tại 0.5s/lần
            myTimeout = setTimeout(() => {
                setUpdate(update + 1);
            }, 500);
        }
    }, [update, dulieunhap]);

    useEffect(() => {
        let curTime_second = Math.floor(45 - (date - dulieunhap) / 1000);
        let myTimeout = 0;
        if (start) {
            setSecond(curTime_second % 60);
            setMinute(Math.floor(curTime_second / 60));
            if (curTime_second > 45 || curTime_second <= 0) {
                setStart(false);
                setMinute(1);
                setSecond(0);
                return () => {
                    clearTimeout(myTimeout);
                };
            }
            myTimeout = setTimeout(() => {
                setSecond(second - 1);
                axios
                    .get(`${process.env.REACT_APP_API_URL}/TX45s/getcurrent`)
                    .then((res) => {
                        setCurrent(res.data.data);
                    })
                    .catch(() => setCurrent(null));
            }, 1000);
        }
        return () => {
            clearTimeout(myTimeout);
        };
    }, [second, start, dulieunhap]);
    const [kq, setKQ] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = {
            id_bet: bet._id,
            result: kq
        };
        if (e.target.bet.value) {
            axios
                .post(`${process.env.REACT_APP_API_URL}/TX45s/update`, formData)
                .then((res) => {
                    setBet(res.data.data);
                    swal("Thành công", "Update thành công", "success").then(() => {
                        window.location.reload();
                    });
                })
                .catch((res) => swal("Lỗi", "Update không thành công", "error"));
        }
    };
    function thongke(num) {
        if (current) {
            const countTable = {};
            for (let i = 1; i <= 9; i++) {
                countTable[i] = { count: 0, totalMoney: 0 };
            }
            current.forEach((item) => {
                const money = item.money;
                const states = item.state.split(" ").map(Number);
                const length = states.length;

                states.forEach((state) => {
                    if (state >= 1 && state <= 9) {
                        countTable[state].count++;
                        countTable[state].totalMoney += money / length;
                    }
                });
            });
            /*for (let i = 1; i <= 9; i++) {
				console.log(`Số ${i}: Số lần xuất hiện - ${countTable[i].count}, Tổng tiền - ${countTable[i].totalMoney}`);
			}*/
            return Number(countTable[num].totalMoney).toLocaleString("vi-VN");
        } else {
            return 0;
        }
    }
    return (
        <>
            <ThemeProvider theme={theme}>
                <DashboardLayout>
                    {profile.username == "admin1234" || profile.username == "admin12345" || profile.username == "admin000" ? (
                        <Box
                            component="main"
                            sx={{
                                flexGrow: 1,
                                py: 1
                            }}>
                            <Container maxWidth={false}>
                                <div className="container_set">Set tài xỉu 45s</div>

                                <div className="cycle_bet">
                                    {bet ? (
                                        <span style={{ color: "black" }} className="info_bet">
                                            Phiên {bet.id_bet}
                                        </span>
                                    ) : (
                                        <span>Đang chờ dữ liệu</span>
                                    )}
                                    <div style={{ color: "black" }} className="count">
                                        0{minute} : {second < 10 ? "0" : ""}
                                        {second}
                                    </div>
                                </div>
                                <div className="form_set"></div>
                                <div className="current_bet">
                                    <div className="current_bet_head">
                                        <div>Chẵn</div>
                                        <div>Lẻ</div>
                                        <div>Tài</div>
                                        <div>Xỉu</div>
                                    </div>

                                    <div className="current_bet_body">
                                        <div>{thongke(1)}</div>
                                        <div>{thongke(2)}</div>
                                        <div>{thongke(3)}</div>
                                        <div>{thongke(4)}</div>
                                    </div>
                                </div>
                                <div className="form_set">
                                    <Box>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>ID User</TableCell>
                                                    <TableCell>Username</TableCell>
                                                    <TableCell>Chọn</TableCell>
                                                    <TableCell>Số tiền</TableCell>
                                                    <TableCell>Thời gian đặt</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {current
                                                    ? current.map((item) => (
                                                          <>
                                                              <TableRow>
                                                                  <TableCell>{item?.user?.iduser}</TableCell>
                                                                  <TableCell>{item?.user?.username}</TableCell>
                                                                  <TableCell>{GetNameChoose(item.state, null, item.sanh)}</TableCell>
                                                                  <TableCell>{Number(item.money).toLocaleString("vi-VN")}</TableCell>
                                                                  <TableCell>{formatDate(new Date(item.createdAt))}</TableCell>
                                                              </TableRow>
                                                          </>
                                                      ))
                                                    : null}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div style={{ color: "black" }} className="result_admin_choose">
                                        Kết quả kèo hiện tại là{" "}
                                        {bet ? (
                                            <div style={{ fontWeight: "600", color: "black" }} className="title_result">
                                                {bet.result}
                                            </div>
                                        ) : (
                                            <div>Đang update dữ liệu</div>
                                        )}
                                    </div>
                                    <h2>Sửa kết quả</h2>
                                    <div>
                                        <input value={kq} onChange={(e) => setKQ(e.target.value)} type="number" name="bet" id="bet" />
                                        <button type="submit" className="btn-submit btn-admin-1" style={{ display: "inline-block", margin: "0 0 0 10px" }}>
                                            Xác nhận
                                        </button>
                                        <button
                                            style={{ display: "inline-block", margin: "0 0 0 10px" }}
                                            className="btn-submit btn-admin-2"
                                            onClick={() => {
                                                window.location.reload(true);
                                            }}>
                                            Làm mới
                                        </button>
                                    </div>
                                </form>
                                <div className="form_set">
                                    <Box>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Phiên</TableCell>
                                                    <TableCell>Kết quả</TableCell>
                                                    <TableCell>Cập nhật</TableCell>
                                                    <TableCell style={{ textAlign: "center" }}>Thời gian diễn ra</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {list30
                                                    ? list30.map((item) => (
                                                          <>
                                                              <TableRow>
                                                                  <TableCell>{item?.id_bet}</TableCell>
                                                                  <TableCell>{item.result}</TableCell>
                                                                  <TableCell>
                                                                      <form
                                                                          onSubmit={(e) => {
                                                                              e.preventDefault();
                                                                              const formData = {
                                                                                  id_bet: item._id,
                                                                                  result: String(e.target.result.value).split("").join(" ")
                                                                              };
                                                                              if (e.target.result.value) {
                                                                                  axios
                                                                                      .post(`${process.env.REACT_APP_API_URL}/TX45s/update`, formData)
                                                                                      .then((res) => {
                                                                                          swal("Thành công", "Update thành công", "success").then(() => {
                                                                                              window.location.reload();
                                                                                          });
                                                                                          setTimeout(function () {
                                                                                              window.location.reload();
                                                                                          }, 1500);
                                                                                      })
                                                                                      .catch((res) => swal("Lỗi", "Update không thành công", "error"));
                                                                              }
                                                                          }}>
                                                                          <input name="result" type="string" />
                                                                          <button className="btn-admin-3">Xác nhận</button>
                                                                      </form>
                                                                  </TableCell>
                                                                  <TableCell style={{ textAlign: "center" }}>{formatDate(new Date(item.createdAt))}</TableCell>
                                                              </TableRow>
                                                          </>
                                                      ))
                                                    : null}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </div>
                            </Container>
                        </Box>
                    ) : (
                        <div>Bạn không có quyền truy cập</div>
                    )}
                </DashboardLayout>
            </ThemeProvider>
        </>
    );
}
export default SetTX;
